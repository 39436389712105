import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ProgressButton from 'components/ProgressButton';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  PourNoteApprovalDto,
  useApprovePourNoteMutation,
  useApproverApprovePourNoteMutation,
  useApproverUnapprovePourNoteMutation,
  useResendPourNoteApprovalMutation,
  PourNoteRoutePointsDto,
  CustomUnitFields,
  UnitSystem,
  Point3DDto
} from 'providers/api';
import { isCustomUnitField } from 'utils/unitConversions';
import { Permission } from 'providers/authorisation';
import { RoutePoint } from 'modules/project/ProjectAreaPage/ProjectStopesPage/canvas/typesInterfaces';
import CreateApproverForm from '../CreateApproverForm';
import ApprovalTable from './ApprovalTable';

interface AwaitingApprovalAreaProps {
  pourReference: string;
  pourId: string;
  pourNoteId: string;
  pourNoteApprovers: PourNoteApprovalDto[];
  complete: boolean;
  isPdf: boolean;
  includeRoutePoints: boolean
  routePoints: RoutePoint[] | undefined;
  unitSystemPreference: UnitSystem;
  customUnitFields: CustomUnitFields[];
}

const AwaitingApprovalArea = ({
  pourReference,
  pourId,
  pourNoteId,
  pourNoteApprovers,
  complete,
  isPdf,
  includeRoutePoints,
  routePoints,
  unitSystemPreference,
  customUnitFields,
}: AwaitingApprovalAreaProps) => {
  const approvePourNoteMutation = useApprovePourNoteMutation(pourId);
  const approverApprovePourNoteMutation = useApproverApprovePourNoteMutation(pourId);
  const approverUnapprovePourNoteMutation = useApproverUnapprovePourNoteMutation(pourId);
  const resendPourNoteApprovalMutation = useResendPourNoteApprovalMutation(pourId);
  const convertTo3DPointDto = (x: number, y: number, z: number) => {
    const point: Point3DDto = {
      x,
      y,
      z,
    };
    return point;
  };

  const handleApprove = () => {
    const isCustom = isCustomUnitField(unitSystemPreference, customUnitFields, 'Pump Pressure');
    const routePointsDto: PourNoteRoutePointsDto[] = routePoints?.map((item) => ({
      distanceFromPump: item.distanceFromPump ?? 0,
      pipeDownstreamName: item.pipeDownstream?.name ?? '',
      pipeUpstreamName: item.pipeUpstream?.name ?? '',
      pointId: item.point.pointId,
      point: convertTo3DPointDto(item.point.screenVector.x, item.point.screenVector.y, item.point.screenVector.z),
      pressure: item.pressure ?? 0,
      pressureUnit: !isCustom ? 'bar' : 'psi',
    })) || [];

    const serializedRoutePoints = JSON.stringify(routePointsDto);
    approvePourNoteMutation.mutate({ pourId, pourNoteId, pourReference, isPdf, includeRoutePoints, routePoints: serializedRoutePoints });
  };

  const handleApproverApprove = (email: string, image?: File) => {
    approverApprovePourNoteMutation.mutate({
      pourId,
      pourNoteId,
      email,
      ...(image && {
        signature: {
          fileName: image.name,
          data: image,
        },
      }),
    });
  };

  const handleApproverUnapprove = (email: string) => {
    approverUnapprovePourNoteMutation.mutate({
      pourId,
      pourNoteId,
      command: {
        email,
      },
    });
  };

  const handleResendEmails = (approvers: string[]) => {
    resendPourNoteApprovalMutation.mutate({
      pourId,
      pourNoteId,
      form: {
        approvers,
      },
    });
  };

  const approvedCount = pourNoteApprovers.filter((approver) => approver.status === 20).length;

  return (
    <ProjectAuthorise
      permission={Permission.ManagePours}
      render={(isAuthorised) => (
        <>
          {(!complete && isAuthorised) && (
            <>
              <Typography variant="subtitle1">Add Approver to Pour Note</Typography>
              <CreateApproverForm pourId={pourId} pourNoteId={pourNoteId} disabled={complete} />
            </>
          )}

          <Typography variant="subtitle1">{`Approved (${approvedCount}/${pourNoteApprovers.length})`}</Typography>
          <Box component={Paper} mb={2}>
            <ApprovalTable
              pourId={pourId}
              data={pourNoteApprovers}
              onApproverApprove={handleApproverApprove}
              onApproverUnapprove={handleApproverUnapprove}
              onResendEmails={handleResendEmails}
              isEditable={!complete && isAuthorised}
            />
          </Box>

          {(!complete && isAuthorised) && (
            <Box component={Paper} p={2} mb={2}>
              <ProgressButton
                loading={approvePourNoteMutation.isLoading}
                onClick={handleApprove}
                disabled={approvedCount !== pourNoteApprovers.length}
                color="primary"
                fullWidth
                variant="contained"
              >
                Approve
              </ProgressButton>
            </Box>
          )}
        </>
      )}
    />
  );
};

export default AwaitingApprovalArea;
