import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { FormikHelpers } from 'formik';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import { Form } from 'hooks/useForm';
import { BackfillRecipeForm, UnitSystem, CustomUnitFields } from 'providers/api';
import { isNumber, round } from 'utils';
import { DictionaryRange } from '../../../canvas/typesInterfaces';
import UnitSystemNumberField from './UnitSystemNumberField';

interface HydraulicAdjustedInputsProps {
  values: BackfillRecipeForm;
  limits: DictionaryRange;
  setFieldValue: FormikHelpers<BackfillRecipeForm>['setFieldValue'];
  setFieldTouched: FormikHelpers<BackfillRecipeForm>['setFieldTouched'];
  classes: ClassNameMap;
  unitSystemPreference: UnitSystem;
  customUnitFields: CustomUnitFields[];
  hasError: Form['helpers']['hasError'];
  getErrorHelpText: Form['helpers']['getErrorHelpText'];
  handleBlur: (e: any) => void | void;
}

const decimalToPercentage = (value: number) => (value + Number.EPSILON) * 100;

const HydraulicAdjustedInputs = ({
  values,
  limits,
  setFieldValue,
  setFieldTouched,
  classes,
  unitSystemPreference,
  customUnitFields,
  hasError,
  getErrorHelpText,
  handleBlur,
}: HydraulicAdjustedInputsProps) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Divider className={classes.fullWidthDivider} />
    </Grid>
    {/* Concentration: Optimal vs. Adjusted */}
    <Grid item xs={6}>
      <FormHelperText id="optimal-concentration-helper-text" component="label">Optimal Concentration</FormHelperText>
      <TextField
        size="small"
        type="number"
        disabled
        fullWidth
        id="optimal-concentration-input"
        name="optimal-concentration-input"
        aria-labelledby="optimal-concentration-helper-text"
        value={(values.hydraulicOptimisedSpecification?.massConcentration ?? 0).toFixed(2)}
        InputProps={{
        endAdornment: <InputAdornment position="end">%m</InputAdornment>,
      }}
      />
    </Grid>

    <Grid item xs={6}>
      <FormHelperText id="adjusted-concentration-helper-text" component="label">Adjusted Concentration</FormHelperText>
      <TextField
        size="small"
        type="number"
        fullWidth
        id="hydraulicSelectedSpecification.massConcentration"
        name="hydraulicSelectedSpecification.massConcentration"
        value={values.hydraulicSelectedSpecification?.massConcentration}
        onChange={
          (event) => setFieldValue(
            'hydraulicSelectedSpecification.massConcentration',
            event.target.value && isNumber(Number(event.target.value))
              ? Number(event.target.value)
              : undefined,
          )
        }
        error={hasError('hydraulicSelectedSpecification.massConcentration')}
        helperText={getErrorHelpText('hydraulicSelectedSpecification.massConcentration')}
        aria-labelledby="adjusted-concentration-helper-text"
        InputProps={{
        endAdornment: <InputAdornment position="end">%m</InputAdornment>,
      }}
        onBlur={() => {
        setFieldValue('hydraulicSelectedSpecification.massConcentration', Number(values.hydraulicSelectedSpecification?.massConcentration?.toFixed(2)));
        setFieldTouched('hydraulicSelectedSpecification.massConcentration', true, true);
      }}
      />
    </Grid>

    <Grid item xs={6} />

    <Grid item xs={6}>
      <Box mx={1}>
        <Slider
          value={values.hydraulicSelectedSpecification?.massConcentration}
          min={decimalToPercentage(limits.concentration.min)}
          step={round(limits.concentration.interval * 100)}
          max={decimalToPercentage(limits.concentration.max)}
          onChange={(e, n) => setFieldValue('hydraulicSelectedSpecification.massConcentration', parseFloat((n as number).toFixed(2)))}
          aria-labelledby="adjusted-concentration-helper-text"
        />
      </Box>
    </Grid>

    {/* not editable but returned from optimiser */}
    <Grid item xs={6}>
      <FormHelperText id="settling-velocity-helper-text" component="label">Settling Velocity</FormHelperText>
      <UnitSystemNumberField
        unitSystem={unitSystemPreference}
        customUnitFields={customUnitFields}
        unitInfo={unitLabels[BasicDisplayUnits.Speed]}
        disabled
        size="small"
        decimalPlaces={2}
        id="settling-velocity"
        name="settling-velocity"
        aria-labelledby="settling-velocity-helper-text"
        value={round(values.hydraulicOptimisedSpecification?.settlingVelocity ?? 0)}
        error={hasError('hydraulicOptimisedSpecification.settlingVelocity')}
        helperText={getErrorHelpText('hydraulicOptimisedSpecification.settlingVelocity')}
        onBlur={handleBlur}
      />
    </Grid>

    <Grid item xs={6}>
      <FormHelperText id="minimum-velocity-helper-text" component="label">Minimum Velocity</FormHelperText>
      <UnitSystemNumberField
        unitSystem={unitSystemPreference}
        customUnitFields={customUnitFields}
        unitInfo={unitLabels[BasicDisplayUnits.Speed]}
        disabled
        decimalPlaces={2}
        size="small"
        type="number"
        fullWidth
        id="mimimum-velocity-input"
        name="minimum-velocity-input"
        value={round(values.hydraulicOptimisedSpecification?.minVelocity ?? 0)}
        aria-labelledby="minimum-velocity-helper-text"
        error={hasError('hydraulicOptimisedSpecification.minVelocity')}
        helperText={getErrorHelpText('hydraulicOptimisedSpecification.minVelocity')}
        onBlur={handleBlur}
      />
    </Grid>

  </Grid>
  );

export default HydraulicAdjustedInputs;
