import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { FormikHelpers } from 'formik';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import { Form } from 'hooks/useForm';
import {
 BackfillRecipeForm,
 UnitSystem,
 UcsCoefficientsDto,
 CustomUnitFields
} from 'providers/api';
import { isNumber, round } from 'utils';
import { DictionaryRange } from '../../../canvas/typesInterfaces';
import UnitSystemNumberField from './UnitSystemNumberField';

interface AdjustedInputsProps {
  values: BackfillRecipeForm;
  limits: DictionaryRange;
  unitSystemPreference: UnitSystem;
  customUnitFields: CustomUnitFields[];
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto } | undefined
  setFieldValue: FormikHelpers<BackfillRecipeForm>['setFieldValue'];
  setFieldTouched: FormikHelpers<BackfillRecipeForm>['setFieldTouched'];
  hasError: Form['helpers']['hasError'];
  getErrorHelpText: Form['helpers']['getErrorHelpText'];
  classes: ClassNameMap;
}

const decimalToPercentage = (value: number) => (value + Number.EPSILON) * 100;

const PasteAdjustedInputs = ({
  values,
  limits,
  unitSystemPreference,
  targetDaysCoefficients,
  customUnitFields,
  setFieldValue,
  setFieldTouched,
  hasError,
  getErrorHelpText,
  classes,
}: AdjustedInputsProps) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Divider className={classes.fullWidthDivider} />
    </Grid>

    {/* Actual Pump Pressure */}
    <Grid item xs={12}>
      <FormHelperText id="pump-pressure-helper-text" component="label">Adjusted Pump Pressure</FormHelperText>
      <UnitSystemNumberField
        unitSystem={unitSystemPreference}
        customUnitFields={customUnitFields}
        unitInfo={unitLabels[BasicDisplayUnits.PressurePump]}
        decimalPlaces={1}
        disabled
        error={false}
        helperText={false}
        size="small"
        id="AdjustedPumpPressure"
        name="AdjustedPumpPressure"
        aria-labelledby="pump-pressure-helper-text"
        value={values.pasteSelectedSpecification?.pumpPressure}
      />
    </Grid>

    {/* Adjusted UCS Strength */}
    <Grid item xs={7}>
      <FormHelperText id="adjusted-ucs-strength-helper-text" component="label">Adjusted UCS Strength</FormHelperText>
      <UnitSystemNumberField
        unitSystem={unitSystemPreference}
        customUnitFields={customUnitFields}
        unitInfo={unitLabels[BasicDisplayUnits.PressureUCS]}
        decimalPlaces={2}
        disabled
        error={false}
        helperText={false}
        size="small"
        id="AdjustedUCSStrength"
        name="AdjustedUCSStrength"
        aria-labelledby="adjusted-ucs-strength-helper-text"
        value={values.pasteSelectedSpecification?.ucsStrength}
      />
    </Grid>
    <Grid item xs={1}>
      <div className={classes.at}>
        at
      </div>
    </Grid>
    <Grid item xs={4}>
      <FormHelperText id="adjusted-days-helper-text" component="label">Days</FormHelperText>
      <TextField
        disabled
        size="small"
        type="number"
        error={false}
        helperText={false}
        fullWidth
        id="Adjusted Days"
        name="Adjusted Days"
        aria-labelledby="adjusted-days-helper-text"
        value={values.pasteSpecification?.targetDays ?? Object.keys(targetDaysCoefficients ?? {})[0]}
      />
    </Grid>

    {/* Concentration: Optimal vs. Adjusted */}
    <Grid item xs={6}>
      <FormHelperText id="optimal-concentration-helper-text" component="label">Optimal Concentration</FormHelperText>
      <TextField
        size="small"
        type="number"
        disabled
        fullWidth
        id="optimal-concentration-input"
        name="optimal-concentration-input"
        aria-labelledby="optimal-concentration-helper-text"
        value={(values.pasteOptimisedSpecification?.massConcentration ?? 0).toFixed(2)}
        InputProps={{
          endAdornment: <InputAdornment position="end">%m</InputAdornment>,
        }}
      />
    </Grid>

    <Grid item xs={6}>
      <FormHelperText id="adjusted-concentration-helper-text" component="label">Adjusted Concentration</FormHelperText>
      <TextField
        size="small"
        type="number"
        fullWidth
        id="adjusted-concentration-input"
        name="adjusted-concentration-input"
        value={values.pasteSelectedSpecification?.massConcentration}
        onChange={
          (event) => setFieldValue(
            'pasteSelectedSpecification.massConcentration',
            event.target.value && isNumber(Number(event.target.value))
              ? Number(event.target.value)
              : undefined,
          )
        }
        aria-labelledby="adjusted-concentration-helper-text"
        InputProps={{
          endAdornment: <InputAdornment position="end">%m</InputAdornment>,
        }}
        onBlur={() => {
          setFieldValue('pasteSelectedSpecification.massConcentration', Number(values.pasteSelectedSpecification?.massConcentration?.toFixed(2)));
          setFieldTouched('pasteSelectedSpecification.massConcentration', true, true);
        }}
        error={hasError('pasteSelectedSpecification.massConcentration')}
        helperText={getErrorHelpText('pasteSelectedSpecification.massConcentration')}
      />
    </Grid>

    <Grid item xs={6} />

    <Grid item xs={6}>
      <Box mx={1}>
        <Slider
          value={values.pasteSelectedSpecification?.massConcentration}
          min={decimalToPercentage(limits.concentration.min)}
          step={round(limits.concentration.interval * 100)}
          max={decimalToPercentage(limits.concentration.max)}
          onChange={(e, n) => setFieldValue('pasteSelectedSpecification.massConcentration', n as number)}
          aria-labelledby="adjusted-concentration-helper-text"
        />
      </Box>
    </Grid>

    {/* Cement: Optimal vs. Adjusted */}
    <Grid item xs={6}>
      <FormHelperText id="optimal-cement-helper-text">Optimal Binder</FormHelperText>
      <TextField
        size="small"
        type="number"
        disabled
        fullWidth
        id="optimal-strength-input"
        name="optimal-strength-input"
        value={values.pasteOptimisedSpecification?.binderContent}
        aria-labelledby="optimal-cement-helper-text"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </Grid>

    <Grid item xs={6}>
      <FormHelperText id="adjusted-cement-helper-text">Adjusted Binder</FormHelperText>
      <TextField
        size="small"
        type="number"
        fullWidth
        id="adjusted-cement-input"
        name="adjusted-cement-input"
        value={values.pasteSelectedSpecification?.binderContent}
        onChange={(event) => isNumber(Number(event.target.value)) && setFieldValue('pasteSelectedSpecification.binderContent', Number(event.target.value))}
        aria-labelledby="adjusted-cement-helper-text"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        onBlur={() => setFieldTouched('pasteSelectedSpecification.binderContent', true, true)}
        error={hasError('pasteSelectedSpecification.binderContent')}
        helperText={getErrorHelpText('pasteSelectedSpecification.binderContent')}
      />
    </Grid>

    <Grid item xs={6}>
      <FormControl />
    </Grid>

    <Grid item xs={6}>
      <Box mx={1}>
        <Slider
          value={values.pasteSelectedSpecification?.binderContent}
          min={decimalToPercentage(limits.cement.min)}
          max={decimalToPercentage(limits.cement.max)}
          step={limits.cement.interval * 100}
          onChange={(e, n) => setFieldValue('pasteSelectedSpecification.binderContent', n as number)}
          aria-labelledby="adjusted-cement-helper-text"
        />
      </Box>
    </Grid>
  </Grid>
);

export default PasteAdjustedInputs;
