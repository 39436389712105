import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  createStyles,
  makeStyles
} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import Actions from 'components/Actions';
import AlertText from 'components/AlertText';
import Temporal from 'components/Temporal';
import PourDetails from 'modules/project/PourDetails';
import {
  ROUTE_PATH as PourRoutePath,
  POUR_PARAM_IDENTIFIER as pourParamIdentifier,
  ROUTE_PARAM_IDENTIFIER as projectParamIdentifier,
  STOPE_PARAM_IDENTIFIER as stopeParamIdentifier
} from 'modules/project/PourDetailsPage/constants';
import {
  DisplayUnits,
  FillType, PourDto,
  ThroughputControlType,
  UnitSystem,
  useMineModelRheologyDataListQuery,
  usePoursQuery,
  UcsCoefficientsDto,
  CustomUnitFields,
  useRecipesQuery,
  RheologyDataDto
} from 'providers/api';
import {
  find,
  indexBy,
  prop,
  propEq
} from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DEFAULT_SHORT_DATE_CONFIG, resolveTemplate } from 'utils';
import PourStatusDisplay from 'modules/project/constants/pour';
import ListSkeleton from '../ListSkeleton';
import { MineModelApi } from '../../canvas/cmodel';

const useStyles = makeStyles(() => createStyles({
  root: {
    height: 'calc(100% - 190px)',
    overflowY: 'auto',
  },
  headerTitle: {
    maxWidth: '80%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  alignRight: {
    textAlign: 'right',
  },
}));

interface PoursTabPanelProps {
  projectId: string;
  stopeId: string;
  displayUnitPreferences: DisplayUnits[];
  unitSystemPreference: UnitSystem;
  fillType: FillType;
  throughputControlType: ThroughputControlType;
  customUnitFields: CustomUnitFields[];
  mineModelApi?: MineModelApi | null;
  initialRheologyDataset?: RheologyDataDto;
  nodeId: string;
}

const PoursTabPanel = ({
  projectId,
  stopeId,
  displayUnitPreferences,
  unitSystemPreference,
  fillType,
  throughputControlType,
  customUnitFields,
  mineModelApi,
  initialRheologyDataset,
  nodeId,
}: PoursTabPanelProps) => {
  const history = useHistory();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [editing, setEditing] = React.useState<string | false>(false);
  const classes = useStyles();
  const { data, isLoading } = usePoursQuery({ projectId, stopeId });
  const { data: recipes } = useRecipesQuery({ projectId, stopeId });
  const pours = data ? data.slice().reverse() : [];
  const { isLoading: rheologyDataSummaryListIsLoading, data: rheologyDataSummaryList } = useMineModelRheologyDataListQuery(
    {
      mineModelId: projectId,
      rheologyDataSetIds: pours ? pours?.map((pour) => pour.rheologyDataSetId ?? '') : [],
    },
    {
      enabled: !!pours && pours.length > 0,
    },
  );

  const rheologyDataSummariesIndexedById = rheologyDataSummaryList ? indexBy(prop('rheologyDataSetId'), rheologyDataSummaryList) : {};

  const updateMineModelVisuals = (recipeIdParam: string) => {
    const recipe = find(propEq('entityId', recipeIdParam), recipes ?? []);

    if (!recipe || !recipes) return;

    if (fillType === FillType.Paste) {
      mineModelApi?.triggerSendPasteRecipe(
        recipe.pasteSelectedSpecification?.ucsStrength || 0,
        recipe.pasteSpecification?.targetDays || (Object.keys(initialRheologyDataset?.ucsCoefficients ?? {})[0] as unknown as number),
        recipe.pasteSelectedSpecification?.massConcentration || 0,
        recipe.pasteSelectedSpecification?.binderContent || 0,
        recipe.pasteSelectedSpecification?.pumpPressure || 0,
        initialRheologyDataset?.tailingsDrySolidsDensity,
        initialRheologyDataset?.binderDryParticleDensity,
        initialRheologyDataset?.carrierFluidDensity,
        throughputControlType === ThroughputControlType.DryTonnage ? recipe.pasteSpecification?.throughput : undefined,
        throughputControlType === ThroughputControlType.FlowRate ? recipe.pasteSpecification?.throughput : undefined,
        throughputControlType === ThroughputControlType.WetTonnage ? recipe.pasteSpecification?.throughput : undefined,
      );
    }

    if (fillType === FillType.Hydraulic) {
      mineModelApi?.triggerSendHydraulicRecipe(
        recipe.hydraulicSpecification?.binderTailingsRatio || 0,
        recipe.hydraulicSelectedSpecification?.massConcentration || 0,
        initialRheologyDataset?.tailingsDrySolidsDensity,
        initialRheologyDataset?.binderDryParticleDensity,
        initialRheologyDataset?.carrierFluidDensity,
        throughputControlType === ThroughputControlType.DryTonnage ? recipe.hydraulicSpecification?.throughput : undefined,
        throughputControlType === ThroughputControlType.FlowRate ? recipe.hydraulicSpecification?.throughput : undefined,
        throughputControlType === ThroughputControlType.WetTonnage ? recipe.hydraulicSpecification?.throughput : undefined,
      );
    }
  };

  const handleChange = (pour: PourDto) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? pour.entityId : false);
    if (pour.entityId === editing) setEditing(false);
    updateMineModelVisuals(pour.recipeId as string);
  };

  const handleViewPour = (pour: PourDto) => {
    const modelState = mineModelApi?.getModelState();
    modelState?.setSelectedStope(nodeId);
    const routePoints = modelState?.selectedRoute?.routePoints || [];

    history.push({
      pathname: resolveTemplate(PourRoutePath, {
        [projectParamIdentifier]: projectId,
        [stopeParamIdentifier]: stopeId,
        [pourParamIdentifier]: pour.entityId,
      }),
      state: { routePoints },
    });
  };

  const collatedDisplayValues = (pour: PourDto) => {
    if (!rheologyDataSummariesIndexedById[pour.rheologyDataSetId]) {
      return {
        mixerCoefficients: undefined,
        specificGravity: undefined,
        heightOfCylinder: undefined,
        yieldStressA: undefined,
        yieldStressB: undefined,
        targetDaysCoefficients: undefined,
        tailingsDrySolidsDensity: undefined,
        carrierFluidDensity: undefined,
        binderDryParticleDensity: undefined,
      };
    }

    const firstYieldStressCoefficient = rheologyDataSummariesIndexedById[pour.rheologyDataSetId].yieldStressCoefficients;

    return {
      mixerCoefficients: rheologyDataSummariesIndexedById[pour.rheologyDataSetId].mixerCoefficients,
      specificGravity: rheologyDataSummariesIndexedById[pour.rheologyDataSetId].tailingsDrySolidsDensity,
      heightOfCylinder: rheologyDataSummariesIndexedById[pour.rheologyDataSetId].heightOfCylinder,
      yieldStressA: firstYieldStressCoefficient?.coefficient1,
      yieldStressB: firstYieldStressCoefficient?.coefficient2,
      targetDaysCoefficients: rheologyDataSummariesIndexedById[pour.rheologyDataSetId].ucsCoefficients,
      tailingsDrySolidsDensity: rheologyDataSummariesIndexedById[pour.rheologyDataSetId].tailingsDrySolidsDensity,
      carrierFluidDensity: rheologyDataSummariesIndexedById[pour.rheologyDataSetId].carrierFluidDensity,
      binderDryParticleDensity: rheologyDataSummariesIndexedById[pour.rheologyDataSetId].binderDryParticleDensity,
    };
  };

  if (isLoading || rheologyDataSummaryListIsLoading) {
    return <ListSkeleton numberOfListItems={6} />;
  }

  if (!pours) {
    return <AlertText severity="info">No pours have been added.</AlertText>;
  }
  return (
    <div className={classes.root}>
      {/* View, edit and delete recipes */}
      {!rheologyDataSummaryListIsLoading && pours.map((pour, index) => (
        <Accordion key={pour.entityId} expanded={expanded === pour.entityId} onChange={handleChange(pour)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`panel-${index}-header`}
          >
            <Grid container spacing={2} wrap="wrap">
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">{pour.reference}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">{PourStatusDisplay[pour.status]}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="caption">
                  {pour.intendedPourDate && (
                  <Temporal temporal={pour.intendedPourDate} options={DEFAULT_SHORT_DATE_CONFIG} />
                )}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={1}>
              <Typography variant="overline">{`Recipe: ${pour.recipeReference}`}</Typography>

              {!rheologyDataSummariesIndexedById[pour.rheologyDataSetId] && <Alert severity="error">Rheology data no longer available</Alert>}

              <PourDetails
                pour={pour}
                displayUnitPreferences={displayUnitPreferences}
                unitSystemPreference={unitSystemPreference}
                customUnitFields={customUnitFields}
                mixerCoefficients={collatedDisplayValues(pour).mixerCoefficients}
                specificGravity={collatedDisplayValues(pour).specificGravity}
                heightOfCylinder={collatedDisplayValues(pour).heightOfCylinder}
                yieldStressA={collatedDisplayValues(pour).yieldStressA}
                yieldStressB={collatedDisplayValues(pour).yieldStressB}
                fillType={fillType}
                tailingsSolidsDensity={collatedDisplayValues(pour).tailingsDrySolidsDensity}
                carrierFluidDensity={collatedDisplayValues(pour).carrierFluidDensity}
                binderParticleDensity={collatedDisplayValues(pour).binderDryParticleDensity}
                throughputControlType={throughputControlType}
                targetDaysCoefficients={collatedDisplayValues(pour).targetDaysCoefficients as { [key: string]: UcsCoefficientsDto; }}
              />

              <Box mt={1}>
                <Actions
                  right={[
                    ['view', (
                      <Button color="primary" onClick={() => handleViewPour(pour)}>
                        View
                      </Button>
                    )],
                  ]}
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default PoursTabPanel;
