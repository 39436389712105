import {
  BackfillRecipeDto,
  BackfillRecipeForm,
  BackfillWarningDto,
  FillType,
  UcsCoefficientsDto
} from 'providers/api';
import {
  SchemaOf,
  array,
  boolean,
  number,
  object,
  string
} from 'yup';
import { DictionaryRange, OptimisationErrorType, Range } from '../../../canvas/typesInterfaces';

const fillTypeSchema = (currentFillType: FillType, expectedFillType: FillType) => number()
  .typeError('Must be a number')
  .test(
    'is-hydraulic-fill',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} is not expected fill type',
    (value) => currentFillType !== expectedFillType || value !== undefined || value !== null,
  );

const generateBackfillSpecificationSchema = (fillType: FillType, maxPumpPressure: number,
  optimisationError: OptimisationErrorType, ucsCoeffiients: { [key: string]: UcsCoefficientsDto; }) => object().shape({
  targetUCSStrength: fillTypeSchema(fillType, FillType.Paste)
    .default(0)
    .test('is-inside-min-max-range', 'ucs is out of range', (value: number, context: any) => {
      const { targetDays } = context.parent;

      // is targetDays is missing return valid
      if (!targetDays) return true;

      const coefficient = ucsCoeffiients[targetDays] as UcsCoefficientsDto;

      // is coefficient is missing return valid
      if (!coefficient) return true;

      const min = coefficient.minUCS;
      const max = coefficient.maxUCS;

      if (min !== null && min !== undefined) {
        if (value < min) return context.createError({ message: `UCS must be above or equal on ${min}` });
      }

      if (max !== null && max !== undefined) {
        if (value > max) return context.createError({ message: `UCS must be below or equal on ${max}` });
      }

      return true;
    })
    .test(
      'optimisationErrorLow',
      'Optimisation error. Try a lower UCS value',
      () => optimisationError !== OptimisationErrorType.UCS_TOO_HIGH,
    )
    .test(
      'optimisationErrorHigh',
      'Optimisation error. Try a higher UCS value',
      () => optimisationError !== OptimisationErrorType.UCS_TOO_LOW,
    )
    .test(
      'optimisationErrorOther',
      'Optimisation error.',
      () => optimisationError !== OptimisationErrorType.OTHER,
    ),
  targetDays: fillTypeSchema(fillType, FillType.Paste)
    .min(1, '> 0'),
  throughput: fillTypeSchema(fillType, FillType.Paste),
  pumpPressure: fillTypeSchema(fillType, FillType.Paste)
    .min(0, '>= 0')
    .max(maxPumpPressure, `<= ${maxPumpPressure}`),
});

const PasteOptimisedSpecificationSchema = (fillType: FillType) => object().shape({
  massConcentration: fillTypeSchema(fillType, FillType.Paste),
  binderContent: fillTypeSchema(fillType, FillType.Paste),
  ucsStrength: fillTypeSchema(fillType, FillType.Paste),
});

const PasteSelectedSpecificationSchema = (fillType: FillType, limits: DictionaryRange) => object().shape({
  massConcentration: fillTypeSchema(fillType, FillType.Paste)
    .min(limits.concentration.min * 100, `must be above ${limits.concentration.min * 100}`)
    .max(limits.concentration.max * 100, `must be below ${limits.concentration.max * 100}`),
  binderContent: fillTypeSchema(fillType, FillType.Paste)
    .min(limits.cement.min * 100, `must be above ${limits.cement.min * 100}`)
    .max(limits.cement.max * 100, `must be below ${limits.cement.max * 100}`),
  ucsStrength: fillTypeSchema(fillType, FillType.Paste),
});

const HydraulicBackfillSpecificationSchema = (fillType: FillType) => object().shape({
  binderTailingsRatio: fillTypeSchema(fillType, FillType.Hydraulic),
  throughput: fillTypeSchema(fillType, FillType.Hydraulic),
});

const HydraulicOptimisedSpecificationSchema = (fillType: FillType) => object().shape({
  massConcentration: fillTypeSchema(fillType, FillType.Hydraulic),
  settlingVelocity: fillTypeSchema(fillType, FillType.Hydraulic),
  minimumVelocity: fillTypeSchema(fillType, FillType.Hydraulic),
  maxPipePressure: fillTypeSchema(fillType, FillType.Hydraulic),
});

const HydraulicSelectedSpecificationSchema = (fillType: FillType, massConcentrationLimits: Range) => object().shape({
  massConcentration: fillTypeSchema(fillType, FillType.Hydraulic)
    .min(massConcentrationLimits.min * 100, `must be above ${massConcentrationLimits.min * 100}`)
    .max(massConcentrationLimits.max * 100, `must be above ${massConcentrationLimits.max * 100}`),
  settlingVelocity: fillTypeSchema(fillType, FillType.Hydraulic),
  minimumVelocity: fillTypeSchema(fillType, FillType.Hydraulic),
  maxPipePressure: fillTypeSchema(fillType, FillType.Hydraulic),
});

const SpoolSpecificationSchema = object().shape({
  spoolId: string().required(),
  nodeAId: string().required(),
  nodeBId: string().required(),
  pipeId: string().required(),
  allowFlowAB: boolean().required(),
  allowFlowBA: boolean().required(),
  chokeLength: number().required(),
});

const warningSchema: SchemaOf<BackfillWarningDto> = object().shape({
  code: string(),
  display: string(),
});

const generateRecipeSchema = (
  fillType: FillType,
  maxPumpPressure: number,
  limits: DictionaryRange,
  optimisationError: OptimisationErrorType,
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto; },
): SchemaOf<BackfillRecipeForm> => object().shape({
  reference: string().required(),
  rheologyDataSetId: string().required(),
  warnings: array().of(warningSchema),
  pasteSpecification: generateBackfillSpecificationSchema(fillType, maxPumpPressure, optimisationError, targetDaysCoefficients).nullable(),
  pasteOptimisedSpecification: PasteOptimisedSpecificationSchema(fillType).nullable(),
  pasteSelectedSpecification: PasteSelectedSpecificationSchema(fillType, limits).nullable(),
  hydraulicOptimisedSpecification: HydraulicOptimisedSpecificationSchema(fillType).nullable(),
  hydraulicSelectedSpecification: HydraulicSelectedSpecificationSchema(fillType, limits.concentration).nullable(),
  hydraulicSpecification: HydraulicBackfillSpecificationSchema(fillType).nullable(),
  spools: SpoolSpecificationSchema.nullable(),
});

const generatePasteFillInitialValues = (
  recipe: BackfillRecipeDto | undefined,
  limits: DictionaryRange,
  minDays: number,
  maxPumpPressure: number,
  rheologyDataSetId: string,
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto; },
) => {
  const initialValues: BackfillRecipeForm = {
    reference: '',
    rheologyDataSetId,
    warnings: [],
    spools: [],
    pasteOptimisedSpecification: {
      massConcentration: 0,
      binderContent: 0,
      ucsStrength: 0,
      pumpPressure: 0,
    },
    pasteSelectedSpecification: {
      massConcentration: limits.concentration.min * 100,
      binderContent: limits.cement.min * 100,
      ucsStrength: 0,
      pumpPressure: 0,
    },
  };

  if (recipe && recipe.pasteSpecification) {
    initialValues.pasteSpecification = {
      targetUCSStrength: recipe.pasteSpecification.targetUCSStrength,
      targetDays: recipe.pasteSpecification.targetDays === 0 ? (Object.keys(targetDaysCoefficients ?? {})[0] as unknown as number) : recipe.pasteSpecification.targetDays,
      throughput: recipe.pasteSpecification.throughput,
      throughputControlType: recipe.pasteSpecification.throughputControlType,
      pumpPressure: recipe.pasteSpecification.pumpPressure,
    };
  }

  if (recipe && recipe.pasteSelectedSpecification) {
    initialValues.pasteSelectedSpecification = {
      massConcentration: recipe.pasteSelectedSpecification.massConcentration,
      binderContent: recipe.pasteSelectedSpecification.binderContent,
      ucsStrength: recipe.pasteSelectedSpecification.ucsStrength,
      pumpPressure: recipe.pasteSelectedSpecification.pumpPressure,
    };
  }
  return initialValues;
};

const generateHydraulicFillInitialValues = (
  recipe: BackfillRecipeDto | undefined,
  limits: DictionaryRange,
  rheologyDataSetId: string,
): BackfillRecipeForm => {
  const initialValues: BackfillRecipeForm = {
    reference: '',
    rheologyDataSetId,
    warnings: [],
    spools: [], // Ensure to initialize spools array or provide actual values
    hydraulicOptimisedSpecification: {
      massConcentration: 0,
      settlingVelocity: 0,
      minVelocity: 0,
      maxPipePressure: 0,
    },
    hydraulicSelectedSpecification: {
      massConcentration: limits.concentration.min * 100,
      settlingVelocity: 0,
      minVelocity: 0,
      maxPipePressure: 0,
    },
  };

  if (recipe?.hydraulicSpecification) {
    initialValues.hydraulicSpecification = {
      ...recipe.hydraulicSpecification,
      binderTailingsRatio: recipe.hydraulicSpecification.binderTailingsRatio,
      throughput: recipe.hydraulicSpecification.throughput,
      throughputControlType: recipe.hydraulicSpecification.throughputControlType,
    };
  }

  if (recipe?.hydraulicOptimisedSpecification) {
    const { massConcentration, settlingVelocity, minVelocity, maxPipePressure } = recipe.hydraulicOptimisedSpecification;
    initialValues.hydraulicOptimisedSpecification = {
      ...recipe.hydraulicOptimisedSpecification,
      massConcentration,
      settlingVelocity,
      minVelocity,
      maxPipePressure,
    };
  }

  if (recipe?.hydraulicSelectedSpecification) {
    const { massConcentration, settlingVelocity, minVelocity, maxPipePressure } = recipe.hydraulicSelectedSpecification;
    initialValues.hydraulicSelectedSpecification = {
      ...recipe.hydraulicSelectedSpecification,
      massConcentration,
      settlingVelocity,
      minVelocity,
      maxPipePressure,
    };
  }
  return initialValues;
};

export const recipeSchemaInitialValues = (
  activeRouteSpools: string[],
  recipe: BackfillRecipeDto | undefined,
  limits: DictionaryRange,
  minDays: number,
  maxPumpPressure: number,
  fillType: FillType,
  rheologyDataSetId: string,
  targetDaysCoefficients: { [key: string]: UcsCoefficientsDto; },
): BackfillRecipeForm => ({
  ...(fillType === FillType.Paste ? generatePasteFillInitialValues(recipe, limits, minDays, maxPumpPressure, rheologyDataSetId, targetDaysCoefficients) : {}),
  ...(fillType === FillType.Hydraulic ? generateHydraulicFillInitialValues(recipe, limits, rheologyDataSetId) : {}),
  rheologyDataSetId: recipe?.rheologyDataSetId ?? rheologyDataSetId,
  reference: recipe && recipe.reference ? recipe.reference : '',
  warnings: [],
  spools: activeRouteSpools,
});

export default generateRecipeSchema;
