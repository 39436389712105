import * as Gconstants from '../GConstants';

export const SPOOL_LINE_WIDTH = 1;
export const NODE_RADIUS: number = 2;
export const STOPE_RADIUS: number = 5;
export const NODE_COLOUR = Gconstants.DARK_GREY;
export const STOPE_COLOUR = Gconstants.RED;
export const PIT_COLOR = Gconstants.ORANGE;
export const FRICTIONLOOP_COLOR = Gconstants.BLUE;
export const SPOOL_COLOUR = '#bbb';
export const TELEMETRY_CANVAS_WIDTH = 275;
