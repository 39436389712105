import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ConditionalMenuItem from 'components/ConditionalMenuItem';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  CustomUnitFields,
  ProjectDto,
  StopeLocationDto,
  UnitSystem,
  useMarkStopeAsCompleteMutation,
  useMarkStopeAsIncompleteMutation
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import React from 'react';
import { useParams } from 'react-router';
import StopeProgress from '../StopeProgress';
import useDeleteStope from '../useDeleteStope';
import { convertVolume } from '../canvas/Model/Telemetry/conversions';

interface StopeActionsMenuProps {
  stopeId: string;
  nodeId: string;
  hasIncompletePours: boolean;
  stopeComplete: boolean;

  onEdit: (nodeId: string) => void;
  onDelete: () => void;
  onMarkStopeAsComplete: () => void;
  onMarkStopeAsIncomplete: () => void;
}

const StopeActionMenu = ({ stopeId, nodeId, hasIncompletePours, stopeComplete, onEdit, onDelete, onMarkStopeAsComplete, onMarkStopeAsIncomplete }: StopeActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = () => {
    onEdit(nodeId);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsComplete = () => {
    onMarkStopeAsComplete();
    setAnchorEl(null);
  };

  const handleMarkAsIncomplete = () => {
    onMarkStopeAsIncomplete();
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls={`project-actions-menu-${stopeId}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id={`project-actions-menu-${stopeId}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ProjectAuthorise permission={Permission.ManagePours}>
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Edit Stope</Typography>
          </MenuItem>
          <ConditionalMenuItem
            disabled={hasIncompletePours || stopeComplete}
            disabledReason={stopeComplete ? 'Stope is complete' : 'Unable to complete stope has outstanding Pours'}
            onClick={handleMarkAsComplete}
          >
            <ListItemIcon>
              <AssignmentTurnedInIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Mark Stope Complete</Typography>
          </ConditionalMenuItem>

          <ConditionalMenuItem
            disabled={!stopeComplete}
            disabledReason="Stope is incomplete"
            onClick={handleMarkAsIncomplete}
          >
            <ListItemIcon>
              <SettingsBackupRestoreIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Mark Stope Incomplete</Typography>
          </ConditionalMenuItem>
        </ProjectAuthorise>
        <ProjectAuthorise permission={Permission.ManageStopeData}>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteForeverIcon color="error" fontSize="small" />
            </ListItemIcon>
            <Typography color="error" variant="inherit">Delete Stope</Typography>
          </MenuItem>
        </ProjectAuthorise>
      </Menu>
    </>
  );
};

interface StopeTitleCardProps {
  stopeLocation?: StopeLocationDto;
  stopeName: string;
  completedVolume: number;
  plannedVolume: number;
  volume?: number;
  stopeComplete: boolean;
  hasIncompletePours: boolean;
  onStopeEdit: (nodeId: string) => void;
  onDeleteSuccess?: (nodeId: string) => void;
  unitSystemPreference: UnitSystem;
  customUnitFields: CustomUnitFields[];
  nodeId: string;
}

const StopeTitleCard = ({
  stopeLocation,
  stopeName,
  completedVolume,
  plannedVolume,
  volume,
  hasIncompletePours,
  stopeComplete,
  unitSystemPreference,
  customUnitFields,
  nodeId,
  onStopeEdit,
  onDeleteSuccess,
}: StopeTitleCardProps) => {
  const params = useParams<{ entityId: ProjectDto['entityId'] }>();
  const deleteStopeMutation = useDeleteStope();

  const handleDelete = () => {
    if (stopeLocation) {
      deleteStopeMutation(params.entityId, stopeLocation.nodeId, stopeLocation.stopeId, stopeName, onDeleteSuccess);
    }
  };

  const markStopeAsCompleteMutation = useMarkStopeAsCompleteMutation(params.entityId);
  const handleMarkAsComplete = () => {
    if (stopeLocation) {
      markStopeAsCompleteMutation.mutate(stopeLocation.stopeId);
    }
  };

  const markStopeAsIncompleteMutation = useMarkStopeAsIncompleteMutation(params.entityId);
  const handleMarkAsIncomplete = () => {
    if (stopeLocation) {
      markStopeAsIncompleteMutation.mutate(stopeLocation.stopeId);
    }
  };

  const isImperial = unitSystemPreference === UnitSystem.Imperial;

  return (
    <Card>
      <Box display="flex" alignItems="center" p={1}>
        <Tooltip
          placement="left"
          arrow
          title={(
            <>
              <Typography variant="subtitle2">{`Total: ${convertVolume(volume as number, isImperial, customUnitFields)}`}</Typography>
              <Typography variant="subtitle2">{`Placed: ${convertVolume(completedVolume as number, isImperial, customUnitFields)}`}</Typography>
              <Typography variant="subtitle2">{`Planned: ${convertVolume(plannedVolume as number, isImperial, customUnitFields)}`}</Typography>
            </>
          )}
        >
          <div>
            <StopeProgress completedVolume={completedVolume} plannedVolume={plannedVolume} size={75} volume={volume} />
          </div>
        </Tooltip>
        <Box flexGrow={1} ml={3}><Typography variant="h6" style={{ wordWrap: 'anywhere' as any }}>{stopeName}</Typography></Box>
        <StopeActionMenu
          stopeId={stopeLocation?.stopeId || ''}
          nodeId={nodeId}
          onEdit={onStopeEdit}
          onDelete={handleDelete}
          onMarkStopeAsComplete={handleMarkAsComplete}
          onMarkStopeAsIncomplete={handleMarkAsIncomplete}
          stopeComplete={stopeComplete}
          hasIncompletePours={hasIncompletePours}
        />
      </Box>
    </Card>
  );
};

export default StopeTitleCard;
