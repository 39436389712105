// Complains about being already declared in the upper scope which it is not. Seems like a bug
// eslint-disable-next-line no-shadow
export enum Permission {
  // Projects
  ManageRecipes,
  ViewRecipes,
  ManagePours,
  ViewPours,
  ViewModel,
  ManageStopeData,
  ManageNodeData,
}

export const IMPLIED_PERMISSIONS: { [key: number]: Permission[] } = {
  [Permission.ManageStopeData]: [
    Permission.ViewModel,
  ],
  [Permission.ManageRecipes]: [
    Permission.ViewRecipes,
  ],
  [Permission.ManagePours]: [
    Permission.ViewPours,
  ],
};

// Complains about being already declared in the upper scope which it is not. Seems like a bug
// eslint-disable-next-line no-shadow
export enum BackfillPlannerRole {
  MineManager,
  BackfillEngineer,
}

const PERMISSIONS = {
  [BackfillPlannerRole.MineManager]: [
    Permission.ManagePours,
    Permission.ViewRecipes,
    Permission.ViewModel,
    Permission.ManageStopeData,
    Permission.ManageNodeData,
  ],
  [BackfillPlannerRole.BackfillEngineer]: [
    Permission.ManagePours,
    Permission.ManageRecipes,
    Permission.ManageStopeData,
    Permission.ManageNodeData,
  ],
};

export const hasPermission = (role: BackfillPlannerRole, permission: Permission) => {
  const impliedPermissions = PERMISSIONS[role].flatMap((p) => IMPLIED_PERMISSIONS[p] ?? []);
  const permissions = [...PERMISSIONS[role], ...impliedPermissions];

  return permissions.includes(permission);
};
