import { Instant } from '@js-joda/core';
import {
  Button,
  Checkbox, Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { FormikActions } from 'components/Form';
import ProgressButton from 'components/ProgressButton';
import { BasicDisplayUnits, unitLabels } from 'hooks/useDisplayUnits/useDisplayUnits';
import useForm from 'hooks/useForm';
import {
  MineModelDto,
  ModelNodeDataForm,
  Point3D,
  ProjectDto,
  StopeDataDto,
  StopeLocationDto,
  UpdateModelNodeDataForm
} from 'providers/api';
import {
  SingleNodeMutation,
  useUpdateNodeMutation
} from 'providers/api/useMineModels';
import { useEffect } from 'react';
import { sanitiseNumber } from 'utils';
import {
  SchemaOf,
  boolean,
  number,
  object,
  string
} from 'yup';
import UnitSystemNumberField from '../StopePanelPage/RecipesTab/RecipeForm/UnitSystemNumberField';
import PipeTypeSelectField from './PipeTypeSelectField';

const pointSchema: SchemaOf<Point3D> = object().shape({
  x: number().required('nodeX is required'),
  y: number().required('nodeX is required'),
  z: number().required('nodeX is required'),
  });

const modelNodeDataSchema: SchemaOf<ModelNodeDataForm> = object().shape({
  nodeId: string().required(),
  pipeId: string().default('').required('Pipe type is required'),
  isStope: boolean(),
  isPit: boolean(),
  level: string().notRequired(),
  area: string().notRequired(),
  pit: string().when('isPit', {
    is: true,
    then: string().required('Pit Name is required'),
    otherwise: string().notRequired(),
  }),
  chokeLength: number()
    .min(0, 'Friction Loop must be greater than or equal to 0')
    .required('Friction Loop is required'),
  stopeData: object().shape({
    stopeId: string().when('isStope', {
      is: true,
      then: string(),
      otherwise: string().notRequired(),
    }),
    stopeName: string().default('').when('isStope', {
      is: true,
      then: string().default('').required('Stope name is required'),
      otherwise: string().default('').notRequired(),
    }),
    pipeHorizontalLength: number().default(0).when('isStope', {
      is: true,
      then: number().default(0).required('Horizontal pipe length is required'),
      otherwise: number().default(0).notRequired(),
    }),
    pipeId: string().default('').when('isStope', {
      is: true,
      then: string().default('').required('Pipe type is required'),
      otherwise: string().default('').notRequired(),
    }),
    volume: number().default(0).when('isStope', {
      is: true,
      then: number().default(0).moreThan(0).required('Volume is required'),
      otherwise: number().default(0).notRequired(),
    }),
    pipeVerticalLength: number().default(0).when('isStope', {
      is: true,
      then: number().default(0),
      otherwise: number().default(0).notRequired(),
    }),
    level: string().notRequired(),
    area: string().notRequired(),
  }),
  point: pointSchema.required(),
  previousNodeId: string().default(''),
});

const nodeDataSchema: SchemaOf<UpdateModelNodeDataForm> = object().shape({
  nodeId: string().default(''),
  modelNodeDataForm: modelNodeDataSchema,
});

const singleNodeMutation: SchemaOf<SingleNodeMutation> = object().shape({
  projectName: string().required(),
  nodeId: string().required(),
  data: nodeDataSchema,
});

interface NodeFormProps {
  project: ProjectDto;
  nodeId: string;
  mineModel: MineModelDto;
  onComplete: () => void; // set to null once mutation is complete of cancelled
  onAdd: (stopeLocation: StopeLocationDto, newStope: StopeDataDto, isPit: boolean, isFrictionLoop: boolean) => void;
  onUpdate: (stopeLocation: StopeLocationDto, newStope: StopeDataDto) => void;
}

const NodeForm = ({ project, nodeId, mineModel, onComplete, onAdd, onUpdate }: NodeFormProps) => {
  const stopeLocation = mineModel.reticulationData.stopeLocations.find((location) => location.nodeId === nodeId);
  const isUpdate = !!stopeLocation;
  const stopeData = mineModel.reticulationData.stopes.find((data) => data.stopeId === stopeLocation?.stopeId);
  const nodeData = mineModel.reticulationData.nodes.find((x) => x.nodeId === nodeId);
  const mutation = useUpdateNodeMutation(project.entityId);
  const {
    formik,
    helpers,
  } = useForm({
    mutation,
    formikConfig: {
      initialValues: {
        projectName: project.name,
        nodeId,
        data: {
          nodeId,
          modelNodeDataForm: {
            nodeId,
            previousNodeId: nodeData?.previousNodeId,
            pipeId: nodeData?.pipeId,
            chokeLength: nodeData?.chokeLength,
            pit: nodeData?.pit,
            area: nodeData?.area,
            level: nodeData?.level,
            isStope: !!stopeLocation,
            isPit: nodeData?.isPit,
            point: nodeData?.point,
            stopeData: {
              stopeId: stopeData?.stopeId,
              stopeName: stopeData?.stopeName,
              pipeHorizontalLength: stopeData?.pipeHorizontalLength,
              pipeVerticalLength: stopeData?.pipeVerticalLength,
              pipeId: stopeData?.pipeId,
              volume: stopeData?.volume,
              level: stopeData?.level,
              area: stopeData?.area,
            },
          },
        },
      },
      onSubmit: (nodeDataForm, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        const stopeName = nodeDataForm.data.modelNodeDataForm.stopeData?.stopeName === null || nodeDataForm.data.modelNodeDataForm.stopeData?.stopeName === undefined
          ? '-'
          : nodeDataForm.data.modelNodeDataForm.stopeData?.stopeName;

        const volume = nodeDataForm.data.modelNodeDataForm.stopeData?.volume === null || nodeDataForm.data.modelNodeDataForm.stopeData?.volume === undefined
          ? 0
          : nodeDataForm.data.modelNodeDataForm.stopeData?.volume;

        const pipeId = nodeDataForm.data.modelNodeDataForm.stopeData?.pipeId === null || nodeDataForm.data.modelNodeDataForm.stopeData?.pipeId === undefined
          ? '0'
          : nodeDataForm.data.modelNodeDataForm.stopeData?.pipeId;

        const pipeHorizontalLength = nodeDataForm.data.modelNodeDataForm.stopeData?.pipeHorizontalLength === null
          || nodeDataForm.data.modelNodeDataForm.stopeData?.pipeHorizontalLength === undefined
          ? 0
          : nodeDataForm.data.modelNodeDataForm.stopeData?.pipeHorizontalLength;

        const pipeVerticalLength = nodeDataForm.data.modelNodeDataForm.stopeData?.pipeVerticalLength === null
          || nodeDataForm.data.modelNodeDataForm.stopeData?.pipeVerticalLength === undefined
          ? 0
          : nodeDataForm.data.modelNodeDataForm.stopeData?.pipeVerticalLength;
        const payload = {
          projectName: project.name || '',
          data: {
            nodeId,
            modelNodeDataForm: {
              nodeId: nodeDataForm.data.nodeId,
              previousNodeId: nodeDataForm.data.modelNodeDataForm.previousNodeId,
              pipeId: nodeDataForm.data.modelNodeDataForm.pipeId,
              chokeLength: nodeDataForm.data.modelNodeDataForm.chokeLength,
              pit: nodeDataForm.data.modelNodeDataForm.pit,
              isStope: nodeDataForm.data.modelNodeDataForm.isStope,
              isPit: nodeDataForm.data.modelNodeDataForm.isPit,
              level: nodeDataForm.data.modelNodeDataForm.level,
              area: nodeDataForm.data.modelNodeDataForm.area,
              point: {
                x: nodeDataForm.data.modelNodeDataForm.point?.x,
                y: nodeDataForm.data.modelNodeDataForm.point?.y,
                z: nodeDataForm.data.modelNodeDataForm.point?.z,
              },
              stopeData: {
                stopeId: stopeLocation?.stopeId,
                stopeName,
                pipeHorizontalLength,
                pipeVerticalLength,
                pipeId,
                volume,
                level: nodeDataForm.data.modelNodeDataForm.stopeData?.level,
                area: nodeDataForm.data.modelNodeDataForm.stopeData?.area,
              },
            },
          },
        };

        mutation.mutate(payload as any, {
          onSettled: () => {
            setSubmitting(false);
          },
          onSuccess: (result: any) => {
            const stopeId = stopeLocation?.stopeId ?? result;

            const stopeLocationDto: StopeLocationDto = {
              nodeId: nodeDataForm.nodeId,
              stopeId,
            };

            const { stopeData: stopeFormData } = nodeDataForm.data.modelNodeDataForm;

            const stopeDataDto: StopeDataDto = {
              // Force values as we know they have been successfully validated
              stopeName: stopeFormData.stopeName || '',
              pipeVerticalLength: stopeFormData.pipeVerticalLength,
              pipeHorizontalLength: stopeFormData.pipeHorizontalLength ?? 0,
              pipeLength: 0, // remove this once PipeLength has been removed server side
              pipeId: stopeFormData.pipeId || '1',
              volume: stopeFormData.volume ?? 0,
              level: stopeFormData.level ?? undefined,
              area: stopeFormData.area ?? undefined,
              stopeId,
              references: [],
              physicalPropertiesUpdated: Instant.now(),
              routeUpdated: Instant.now(),
            };
            const isFrictionLoop = !!((nodeDataForm.data.modelNodeDataForm.chokeLength != null && nodeDataForm.data.modelNodeDataForm.chokeLength > 0));
            const isPit = nodeDataForm.data.modelNodeDataForm.isPit === true;

            isUpdate ? onUpdate(stopeLocationDto, stopeDataDto) : onAdd(stopeLocationDto, stopeDataDto, isPit, isFrictionLoop);
            resetForm();
            onComplete();

            // TODO: This should be replaced with a better solution. Reloading the page causes the user significant delay
            window.location.reload();
          },
          onError: (e) => {
            // eslint-disable-next-line
            console.log(e);
          },
        });
      },
      validationSchema: singleNodeMutation,
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    isValid,
    isSubmitting,
  } = formik;

  const handleCloseModal = () => {
    resetForm();
    onComplete();
  };

  const submitButton = [
    'submit',
    (
      <ProgressButton
        variant="contained"
        color="primary"
        type="submit"
        disabled={!isValid}
        loading={isSubmitting}
      >
        Submit
      </ProgressButton>
    ),
  ];

  useEffect(() => {
    if (!values.data.modelNodeDataForm.isPit) {
      setFieldValue('data.modelNodeDataForm.pit', '');
    }
  }, [values.data.modelNodeDataForm.isPit]);

  return (
    <Dialog
      title="Add Node"
      open={!!nodeId}
      onClose={handleCloseModal}
    >
      <DialogTitle id="simple-dialog-title">Node Edit</DialogTitle>
      <Box width={500} m={2}>
        <form onSubmit={handleSubmit}>
          <Box>
            <TextField
              required
              fullWidth
              id="data.nodeId"
              name="data.nodeId"
              label="Node Id"
              value={values.nodeId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('data.nodeId')}
              helperText={helpers.getErrorHelpText('data.nodeId')}
              disabled
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.modelNodeDataForm.point?.x"
              name="data.modelNodeDataForm.point?.x"
              label="Node X"
              value={values.data.modelNodeDataForm.point?.x}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={helpers.getErrorHelpText('data.modelNodeDataForm.point?.x')}
              disabled
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.modelNodeDataForm.point?.y"
              name="data.modelNodeDataForm.point?.y"
              label="Node Y"
              value={values.data.modelNodeDataForm.point?.y}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={helpers.getErrorHelpText('data.modelNodeDataForm.point?.y')}
              disabled
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.modelNodeDataForm.point?.z"
              name="data.modelNodeDataForm.point?.z"
              label="Node Z"
              value={values.data.modelNodeDataForm.point?.z}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={helpers.getErrorHelpText('data.modelNodeDataForm.point?.z')}
              disabled
            />
          </Box>
          <Box pt={2}>
            <PipeTypeSelectField
              pipeTypes={mineModel.reticulationData.pipeData}
              value={values.data.modelNodeDataForm.pipeId ?? ''}
              onChange={(pipeId) => setFieldValue('data.modelNodeDataForm.pipeId', pipeId)}
              label="Pipe Type"
            />
          </Box>

          <Box pt={2}>
            <TextField
              fullWidth
              id="data.modelNodeDataForm.level"
              name="data.modelNodeDataForm.level"
              label="Level"
              value={values.data.modelNodeDataForm.level ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('data.modelNodeDataForm.level')}
              helperText={helpers.getErrorHelpText('data.modelNodeDataForm.level')}
            />
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              id="data.modelNodeDataForm.area"
              name="data.modelNodeDataForm.area"
              label="Area"
              value={values.data.modelNodeDataForm.area ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={helpers.hasError('data.modelNodeDataForm.area')}
              helperText={helpers.getErrorHelpText('data.modelNodeDataForm.area')}
            />
          </Box>
          <Box pt={2}>
            <UnitSystemNumberField
              fullWidth
              type="number"
              unitSystem={project.unitSystemPreference}
              unitInfo={unitLabels[BasicDisplayUnits.Distance]}
              decimalPlaces={1}
              error={helpers.hasError('data.modelNodeDataForm.chokeLength')}
              helperText={helpers.getErrorHelpText('data.modelNodeDataForm.chokeLength')}
              id="data.modelNodeDataForm.chokeLength"
              name="data.modelNodeDataForm.chokeLength"
              label="Friction Loop"
              value={values.data.modelNodeDataForm.chokeLength}
              onChange={(e) => setFieldValue('data.modelNodeDataForm.chokeLength', sanitiseNumber(e.target.value))}
              onBlur={handleBlur}
              customUnitFields={project.customUnitFields}
            />
          </Box>

          <Box pt={2} alignItems="center">
            <Box>
              <Typography variant="subtitle1">Is pressure indicating transmitter</Typography>
            </Box>
            <Box display="flex" alignItems="between">
              <Checkbox
                disabled={false}
                checked={values.data.modelNodeDataForm.isPit}
                onChange={(event) => setFieldValue('data.modelNodeDataForm.isPit', event.target.checked)}
                color="primary"
              />
              <TextField
                fullWidth
                id="data.modelNodeDataForm.pit"
                name="data.modelNodeDataForm.pit"
                label="Pit Name"
                value={values.data.modelNodeDataForm.pit ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={helpers.hasError('data.modelNodeDataForm.pit')}
                helperText={helpers.getErrorHelpText('data.modelNodeDataForm.pit')}
                disabled={!values.data.modelNodeDataForm.isPit}
              />
            </Box>
          </Box>
          <Box pt={2}>
            <Button
              fullWidth
              variant={values.data.modelNodeDataForm.isStope ? 'contained' : 'outlined'}
              size="large"
              disabled={values.data.modelNodeDataForm.isPit || (values.data.modelNodeDataForm.chokeLength || 0) > 0}
              onClick={() => setFieldValue('data.modelNodeDataForm.isStope', !values.data.modelNodeDataForm.isStope)}
            >
              {values.data.modelNodeDataForm.isStope ? 'Remove Stope' : 'Set as Stope'}
            </Button>
          </Box>
          {values.data.modelNodeDataForm.isStope && (
          <>
            <Box pt={2}>
              <TextField
                fullWidth
                id="data.modelNodeDataForm.stopeData.stopeName"
                name="data.modelNodeDataForm.stopeData.stopeName"
                label="Stope Name"
                value={values.data.modelNodeDataForm.stopeData.stopeName ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={helpers.hasError('data.modelNodeDataForm.stopeData.stopeName')}
                helperText={helpers.getErrorHelpText('data.modelNodeDataForm.stopeData.stopeName')}
                required
              />
            </Box>
            <Box pt={2}>
              <UnitSystemNumberField
                fullWidth
                type="number"
                unitSystem={project.unitSystemPreference}
                unitInfo={unitLabels[BasicDisplayUnits.Distance]}
                decimalPlaces={1}
                error={helpers.hasError('data.modelNodeDataForm.stopeData.pipeVerticalLength')}
                helperText={helpers.getErrorHelpText('data.modelNodeDataForm.stopeData.pipeVerticalLength')}
                id="data.modelNodeDataForm.stopeData.pipeVerticalLength"
                name="data.modelNodeDataForm.stopeData.pipeVerticalLength"
                label="Stope Pipe Vertical Length"
                value={values.data.modelNodeDataForm.stopeData.pipeVerticalLength ?? ''}
                onChange={(e) => setFieldValue('data.modelNodeDataForm.stopeData.pipeVerticalLength', sanitiseNumber(e.target.value))}
                onBlur={handleBlur}
                customUnitFields={project.customUnitFields}
              />
            </Box>
            <Box pt={2}>
              <UnitSystemNumberField
                fullWidth
                type="number"
                unitSystem={project.unitSystemPreference}
                unitInfo={unitLabels[BasicDisplayUnits.Distance]}
                decimalPlaces={1}
                error={helpers.hasError('data.modelNodeDataForm.stopeData.pipeHorizontalLength')}
                helperText={helpers.getErrorHelpText('data.modelNodeDataForm.stopeData.pipeHorizontalLength')}
                id="data.modelNodeDataForm.stopeData.pipeHorizontalLength"
                name="data.modelNodeDataForm.stopeData.pipeHorizontalLength"
                value={values.data.modelNodeDataForm.stopeData.pipeHorizontalLength ?? ''}
                label="Stope Pipe Horizontal Length"
                onChange={(e) => setFieldValue('data.modelNodeDataForm.stopeData.pipeHorizontalLength', sanitiseNumber(e.target.value))}
                onBlur={handleBlur}
                customUnitFields={project.customUnitFields}
              />
            </Box>
            <Box pt={2}>
              <PipeTypeSelectField
                pipeTypes={mineModel.reticulationData.pipeData}
                value={values.data.modelNodeDataForm.stopeData.pipeId ?? ''}
                onChange={(pipeId) => setFieldValue('data.modelNodeDataForm.stopeData.pipeId', pipeId ?? '')}
              />
            </Box>
            <Box pt={2}>
              <UnitSystemNumberField
                fullWidth
                type="number"
                unitSystem={project.unitSystemPreference}
                unitInfo={unitLabels[BasicDisplayUnits.Volume]}
                decimalPlaces={1}
                error={helpers.hasError('data.modelNodeDataForm.stopeData.volume')}
                helperText={helpers.getErrorHelpText('data.modelNodeDataForm.stopeData.volume')}
                id="data.modelNodeDataForm.stopeData.volume"
                name="data.modelNodeDataForm.stopeData.volume"
                label="Stope Volume"
                value={values.data.modelNodeDataForm.stopeData.volume}
                onChange={(e) => setFieldValue('data.modelNodeDataForm.stopeData.volume', sanitiseNumber(e.target.value))}
                onBlur={handleBlur}
                customUnitFields={project.customUnitFields}
              />
            </Box>
          </>
)}
          <Box mt={2}>
            <FormikActions
              formik={formik}
              mutation={mutation}
              submitText="Create"
              right={
                [
                  'reset',
                  submitButton as [string, JSX.Element],
                ]
              }
            />
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

export default NodeForm;
