import { capitalize } from 'utils';
import {
  MineModelDto,
  MineModelSummaryDto,
  ReticulationDataImportDto,
  RheologyDataDto,
  RheologyDataForm,
  StopeDataForm,
  UpdateModelNodeDataForm,
  UpdateStopeDataForm
} from './api';
import {
  makeCreateMutation,
  makeDeleteMutation,
  makeGetQuery,
  makeUpdateMutation
} from './useApi';
import { STOPE_BACKFILLS_KEY } from './usePours';
import { RECIPES_KEY } from './useRecipes';

const MINE_MODEL_KEY = 'mine-model';
const MINE_MODEL_SUMMARY_KEY = 'mine-model-summary';
const RHEOLOGY_DATA_EXPORT_KEY = 'rheology-data-export';
const RETICULATION_DATA_EXPORT_KEY = 'reticulation-data-export';

// Mine Model
export interface MineModelMutation {
  projectId: string;
  projectName: string;
  data: ReticulationDataImportDto;
}
export const useMineModelQuery = makeGetQuery<MineModelDto | undefined, string>(
  MINE_MODEL_KEY,
  (api, mineModelId) => () => api.mineModels.getWithRheologyList(mineModelId).catch(() => undefined),
);

export const useMineModelSummaryQuery = makeGetQuery<MineModelSummaryDto | undefined, string>(
  MINE_MODEL_SUMMARY_KEY,
  (api, mineModelId) => () => api.mineModels.getSummary(mineModelId).catch(() => undefined),
);

export const useCreateMineModelMutation = makeCreateMutation<void, MineModelMutation>(
  null,
  (api) => (mutation) => api.mineModels.create(mutation.projectId, mutation.data),
  (mutation) => `Reticulation data added to ${capitalize(mutation.projectName)}.`,
  [RETICULATION_DATA_EXPORT_KEY, MINE_MODEL_SUMMARY_KEY],
);

export const useUpdateMineModelMutation = makeUpdateMutation<void, MineModelMutation>(
  MINE_MODEL_KEY, null,
  (api, projectId) => (mutation) => api.mineModels.update(projectId, mutation.data),
  (mutation) => `Reticulation data updated for ${capitalize(mutation.projectName)}.`,
  [RETICULATION_DATA_EXPORT_KEY, MINE_MODEL_SUMMARY_KEY],
);

// Rheology CRUD
export interface MineModelRheologyDataQuery {
  mineModelId: string;
  rheologyDataSetIds: string[];
}
export const useMineModelRheologyDataListQuery = makeGetQuery<RheologyDataDto[], MineModelRheologyDataQuery>(
  MINE_MODEL_KEY,
  (api, query) => () => api.mineModels.getRheologyDataByListOfIds(query.mineModelId, { rheologyDataSetIds: query.rheologyDataSetIds }),
);

export interface RheologyMutation {
  projectId: string;
  projectName: string;
  data: RheologyDataForm;
  rheologyDatasetId?: string;
}

export const useCreateRheologyMutation = makeUpdateMutation<void, RheologyMutation>(
  MINE_MODEL_KEY, null,
  (api) => (mutation) => api.mineModels.createRheologyData(mutation.projectId, mutation.data),
  (mutation) => `Rheology data added to ${capitalize(mutation.projectName)}.`,
  [RHEOLOGY_DATA_EXPORT_KEY, MINE_MODEL_SUMMARY_KEY],
);

export const useUpdateRheologyMutation = makeUpdateMutation<void, RheologyMutation>(
  MINE_MODEL_KEY, null,
  (api, projectId) => (mutation) => api.mineModels.updateRheology(projectId, mutation.data, mutation.rheologyDatasetId),
  (mutation) => `Rheology data updated for ${capitalize(mutation.projectName)}.`,
  [RHEOLOGY_DATA_EXPORT_KEY, MINE_MODEL_SUMMARY_KEY],
);

export interface SingleRheologyDataSetArgs {
  mineModelId: string;
  rheologyDatasetId: string;
  rheologyDatasetReference?: string;
}

export const useDeleteRheologyDatasetMutation = makeDeleteMutation<void, SingleRheologyDataSetArgs>(
  MINE_MODEL_KEY, null,
  (api) => (mutation) => api.mineModels.removeRheologyDataSet(mutation.mineModelId, mutation.rheologyDatasetId),
  (mutation) => `Rheology data ${mutation.rheologyDatasetReference} successfully deleted`,
  (mutation) => mutation.rheologyDatasetId,
  [RHEOLOGY_DATA_EXPORT_KEY, MINE_MODEL_SUMMARY_KEY],
);

export const useRheologyDataExport = makeGetQuery<RheologyDataDto | undefined, SingleRheologyDataSetArgs>(
  RHEOLOGY_DATA_EXPORT_KEY,
  (api, request) => () => api.mineModels.exportRheologyData(request.mineModelId, request.rheologyDatasetId).catch(() => undefined),
);

export const useRheologyDataQuery = makeGetQuery<RheologyDataDto | undefined, SingleRheologyDataSetArgs>(
  RHEOLOGY_DATA_EXPORT_KEY,
  (api, request) => () => api.mineModels.getRheologyDataSet(request.mineModelId, request.rheologyDatasetId).catch(() => undefined),
);

// Reticulation CRUD

export const useReticulationDataExport = makeGetQuery<ReticulationDataImportDto | undefined, string>(
  RETICULATION_DATA_EXPORT_KEY,
  (api, mineModelId) => () => api.mineModels.exportReticulationData(mineModelId).catch(() => undefined),
);

export interface StopesMutation {
  projectName: string;
  data: UpdateStopeDataForm[];
}

export interface SingleStopeMutation {
  projectName: string | null;
  nodeId: string;
  data: StopeDataForm;
}

export const useUpdateStopeMutation = makeUpdateMutation<void, StopesMutation>(
  MINE_MODEL_KEY, null,
  (api, projectId) => (mutation) => api.mineModels.updateStopes(projectId, mutation.data),
  (mutation) => `Stope data updated for ${capitalize(mutation.projectName)}.`,
  [RETICULATION_DATA_EXPORT_KEY, RECIPES_KEY, MINE_MODEL_SUMMARY_KEY],
);

export const useUpdateNodeToStopeMutation = makeUpdateMutation<string, SingleStopeMutation>(
  MINE_MODEL_KEY, null,
  (api, projectId) => (mutation) => api.mineModels.addStope(projectId, mutation.nodeId, mutation.data),
  (mutation) => `Stope created for ${capitalize(mutation.projectName)}.`,
  [RETICULATION_DATA_EXPORT_KEY, STOPE_BACKFILLS_KEY, MINE_MODEL_SUMMARY_KEY],
);

export interface SingleNodeMutation {
  projectName: string | null;
  nodeId: string;
  data: UpdateModelNodeDataForm;
}
export const useUpdateNodeMutation = makeUpdateMutation<string, SingleNodeMutation>(
  MINE_MODEL_KEY, null,
  (api, projectId) => (mutation) => api.mineModels.updateNode(projectId, mutation.data),
  (mutation) => `Node data updated for ${capitalize(mutation.projectName)}.`,
  [RETICULATION_DATA_EXPORT_KEY, RECIPES_KEY, MINE_MODEL_SUMMARY_KEY],
);

export interface SingleStopeDeleteMutation {
  projectId: string;
  stopeId: string;
  stopeName: string;
  nodeId: string;
}

export const useDeleteStopeMutation = makeDeleteMutation<void, SingleStopeDeleteMutation>(
  MINE_MODEL_KEY, null,
  (api) => (mutation) => api.mineModels.removeStope(mutation.projectId, mutation.stopeId, mutation.nodeId),
  (mutation) => `Stope ${mutation.stopeName} successfully deleted`,
  (mutation) => mutation.projectId,
  [RETICULATION_DATA_EXPORT_KEY, STOPE_BACKFILLS_KEY],
);

export const useMarkStopeAsCompleteMutation = makeUpdateMutation<void, string>(
  MINE_MODEL_KEY, null,
  (api, projectId) => (stopeId) => api.stopes.markComplete(projectId, stopeId),
  () => 'Stope marked as complete',
  [RETICULATION_DATA_EXPORT_KEY, STOPE_BACKFILLS_KEY, MINE_MODEL_SUMMARY_KEY],
);

export const useMarkStopeAsIncompleteMutation = makeUpdateMutation<void, string>(
  MINE_MODEL_KEY, null,
  (api, projectId) => (stopeId) => api.stopes.markIncomplete(projectId, stopeId),
  () => 'Stope marked as incomplete',
  [RETICULATION_DATA_EXPORT_KEY, STOPE_BACKFILLS_KEY, MINE_MODEL_SUMMARY_KEY],
);
