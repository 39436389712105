import { ZonedDateTime } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import ProgressButton from 'components/ProgressButton';
import ProjectAuthorise from 'modules/project/ProjectAuthorise';
import {
  FillType,
  UnitSystem,
  useCreatePourNoteQuery,
  useUpdatePourDateMutation,
  useUpdatePourVolumeMutation,
  CustomUnitFields,
  PourNoteRoutePointsDto,
  Point3DDto
} from 'providers/api';
import { Permission } from 'providers/authorisation';
import { Checkbox, Typography } from '@material-ui/core';
import React from 'react';
import { isCustomUnitField } from 'utils/unitConversions';
import { RoutePoint } from 'modules/project/ProjectAreaPage/ProjectStopesPage/canvas/typesInterfaces';
import UpdatePourDateForm from './UpdatePourDateForm';
import UpdatePourVolumeForm from './UpdatePourVolumeForm';

interface PlannedAreaProps {
  pourReference: string;
  pourId: string;
  fillType: FillType;
  pourDate: ZonedDateTime;
  pourVolume: number;
  volumeRetainedAfterOutflow?: number;
  unitSystemPreference: UnitSystem;
  customUnitFields: CustomUnitFields[];
  includeRoutePoints: boolean;
  routePoints: RoutePoint[] | undefined;
  setIsPdf: (isPdf: boolean) => void;
  setIncludeRoutePoints: (includeRoutePoints: boolean) => void;
}

const PlannedArea = ({
  pourReference,
  pourId,
  fillType,
  pourDate,
  pourVolume,
  volumeRetainedAfterOutflow,
  unitSystemPreference,
  customUnitFields,
  includeRoutePoints,
  routePoints,
  setIsPdf,
  setIncludeRoutePoints,
}: PlannedAreaProps) => {
  const createPourNoteMutation = useCreatePourNoteQuery(pourId);
  const updatePourDateMutation = useUpdatePourDateMutation(pourId);
  const updatePourVolumeMutation = useUpdatePourVolumeMutation(pourId);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeRoutePoints(event.target.checked);
  };

  const convertTo3DPointDto = (x: number, y: number, z: number) => {
    const point: Point3DDto = {
      x,
      y,
      z,
    };
    return point;
  };

  const handleGeneratePourNote = (isPdf: boolean, includePoints: boolean) => {
    const isCustom = isCustomUnitField(unitSystemPreference, customUnitFields, 'Pump Pressure');
    const routePointsDto: PourNoteRoutePointsDto[] = routePoints?.map((item) => ({
      distanceFromPump: item.distanceFromPump ?? 0,
      pipeDownstreamName: item.pipeDownstream?.name ?? '',
      pipeUpstreamName: item.pipeUpstream?.name ?? '',
      pointId: item.point.pointId,
      point: convertTo3DPointDto(item.point.screenVector.x, item.point.screenVector.y, item.point.screenVector.z),
      pressure: item.pressure ?? 0,
      pressureUnit: !isCustom ? 'bar' : 'psi',
    })) || [];
    setIsPdf(isPdf);
    const serializedRoutePoints = JSON.stringify(routePointsDto);
    createPourNoteMutation.mutate({ pourId, pourReference, isPdf, includeRoutePoints: includePoints, routePoints: serializedRoutePoints });
  };

  return (
    <>
      <Box mb={2}>
        <UpdatePourDateForm pourDate={pourDate} mutation={updatePourDateMutation} />
      </Box>
      <Box mb={2}>
        <UpdatePourVolumeForm
          fillType={fillType}
          volume={pourVolume}
          volumeRetainedAfterOutflow={volumeRetainedAfterOutflow}
          unitSystemPreference={unitSystemPreference}
          mutation={updatePourVolumeMutation}
          customUnitFields={customUnitFields}
        />
      </Box>
      <ProjectAuthorise permission={Permission.ManagePours}>
        <Box
          component={Paper}
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Checkbox
              disabled={false}
              checked={includeRoutePoints}
              onChange={handleCheckboxChange}
              color="primary"
            />
            <Typography variant="subtitle1" style={{ marginRight: 8 }}>
              Add selected route points to the pour note
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%">
            <ProgressButton
              variant="contained"
              color="primary"
              onClick={() => handleGeneratePourNote(true, includeRoutePoints)}
              loading={createPourNoteMutation.isLoading}
            >
              Generate Pour Note PDF
            </ProgressButton>
            <ProgressButton
              variant="contained"
              color="primary"
              onClick={() => handleGeneratePourNote(false, includeRoutePoints)}
              loading={createPourNoteMutation.isLoading}
            >
              Generate Pour Note Excel
            </ProgressButton>
          </Box>
        </Box>
      </ProjectAuthorise>
    </>
  );
};

export default PlannedArea;
